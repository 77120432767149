import React from 'react'
import PropTypes from 'prop-types';
import ReactFullpage from '@fullpage/react-fullpage'
import CustomerHero from '../components/surveyhero'
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import Kicker from '../components/kicker'
import ScrollContent from "../components/scrollcontent"
import FeedbackSwiper from "../components/feedback/feedbackswiper"
import Footer from '../components/layout/footer'
import ReactMarkdown from 'react-markdown'
import { navigate } from "gatsby"
import ReactJoyride, { EVENTS } from 'react-joyride';
import Cookies from 'universal-cookie';
import RegistrationModal from "../components/registrationmodalv3"

import '../styles/fullpage.css'

const SEL = 'custom-section';
const SECTION_SEL = `.${SEL}`;

// NOTE: if using fullpage extensions/plugins put them here and pass it as props.
// This is no longer required for the scrollOverflow option.
const pluginWrapper = () => {
  /*
  * require('./fullpage.fadingEffect.min'); // Optional. Required when using the "fadingEffect" extension.
  */
};

const tooltips = ["Survey \"Home\"", "Organizing Conversations", "Placing Orders", "Tracking Open Issues", "Productive Meetings", "Promos and Deals", "Investing in Improvement", "A Better Way",""]

const cookies = new Cookies();
const current = new Date();

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false, //unused at this point in time
      run: false, //added for react-joyride
      height: 0,
      width: 0,
      fullpages: [
        {
          option: 'Section1',
        },
        {
          option: 'Section2',
        },
        {
          option: 'Section3',
        },
        {
          option: 'Section4',
        },
        {
          option: 'Section5',
        },
        {
          option: 'Section6',
        },
        {
          option: 'Section7',
        },
        {
          option: 'Section8',
        },
        {
          option: 'Section9',
        }
      ],
      steps: [
        {
          title: 'Let\'s\ start with a quick tour',
          content: (
            <React.Fragment>
              <p class="text-sm scmd:text-base">We want to get you comfortable with this feedback process and how it's designed...</p>
              <p class="text-sm scmd:text-base">Click next to begin the tour.</p>
            </React.Fragment>
          ),
          placement: 'center',
          target: 'body',
        },
        {
          title: 'A backdrop for this section of the survey',
          content: (
            <React.Fragment>
              <p class="text-sm scmd:text-base">There are six sections in the survey. This area gives you the topic for each section. It’s just meant as a reference for the survey questions.</p>
              {/* below p tag is hidden on mobile to save space and ensure the core message fits on the screen */}
              {/* <p class="hidden scmd:block text-base">They are here to help and for you to use as a reference for the survey questions.</p>  */}
            </React.Fragment>
          ),
          placement: 'auto',
          placementBeacon: 'top',
          target: '#todo-list-1',
        },
        {
          title: 'Like a stick chart, these are for navigation',
          content: 'If you need to come back to a step in the process, this navigation can help you move around.',
          content: (
            <React.Fragment>
              <p class="text-sm scmd:text-base">If for some reason you need to jump around in the process or come back to a question later, use these to help you find your way!</p>
            </React.Fragment>
          ),
          placement: 'auto',
          placementBeacon: 'top',
          target: '.fp-sr-only',
        },
        {
          title: 'This is where the magic happens',
          content: (
            <React.Fragment>
              <p class="text-sm scmd:text-base">There are three questions per section: one about overall feeling, one about technologies used and one that allows you to give us more detail.</p>
            </React.Fragment>
          ),
          placement: 'auto',
          placementBeacon: 'top',
          target: '.swiper-wrapper',
        },
        {
          title: 'These too are like stick charts - they help you navigate the survey!',
          content: (
            <React.Fragment>
              <p class="text-sm scmd:text-base">Click the arrow to move between the first two questions. Once you see the ✓, click it to move to the next section.</p>
              {/* below p tag is hidden on mobile to save space and ensure the core message fits on the screen */}
              {/* <p class="hidden scmd:block text-base">They are here to help and for you use as a reference for the survey questions.</p>  */}
            </React.Fragment>
          ),
          placement: 'left',
          placementBeacon: 'top',
          target: '.swiper-button-next',
        },
        {
          title: 'That\'s\ all there is to it!',
          content: 'That\'s\ it!  We appreciate you taking time to provide us your valuable feedback.',
          content: (
            <React.Fragment>
              <p class="text-sm scmd:text-base">We truly appreciate you taking time to provide us your valuable feedback.</p>
              {/* below p tag is hidden on mobile to save space and ensure the core message fits on the screen */}
              <p class="hidden scmd:block text-base">Thank you!  Click Last to start providing feedback.</p> 
            </React.Fragment>
          ),
          placement: 'center',
          target: 'body',
        },
      ],
    };
    typeof window !== 'undefined' && window.addEventListener("resize", this.update);
  }


  static propTypes = {
    joyride: PropTypes.shape({
      callback: PropTypes.func,
    }),
  };


  static defaultProps = {
    joyride: {},
  };

  selectModal = (info) => {
    this.setState({modal: !this.state.modal}) // true/false toggle

    //navigate to the desired page
    navigate(`/bettercustomer`)
  }

  onLeave(origin, destination, direction) {
    var bNav = document.getElementById("fp-nav");
      if (destination.index === 0) {
        bNav.style.display = "none";
      } else
      {
        bNav.style.display = "block";
      }
  }


  afterLoad(origin, destination, direction) {

    if (process.env.GATSBY_ACTIVE_ENV === 'development') {
      console.log('***A/B TESTING*** destination.index - ' + destination.index)
    }
    if (process.env.GATSBY_ACTIVE_ENV === 'development') {
      console.log('***A/B TESTING*** origin.index - ' + origin.index)
    }

    var bNav = document.getElementById("fp-nav");

    if (destination.index === 0) {
      bNav.style.display = "none";
    } else
    {
      bNav.style.display = "block";
    }
    if (process.env.GATSBY_ACTIVE_ENV === 'development') {
      console.log('***A/B TESTING*** state.width - ' + this.state.width)
    }
    if ((origin.index === 0 ) && (destination.index === 1)) {
      //check for a mobile device, as the page goes from the landing page to the 1st survey page
      if (this.state.width !== 0) {
        if (process.env.GATSBY_ACTIVE_ENV === 'development') {
          console.log('***A/B TESTING*** width state is not zero')
        }
        if (this.state.width < 700){
          //before setting the state to show the walk me tutorial, check to see if a cookie exists (it has been dismissed)
          if(cookies.get('sc_walk_on_mobile')===null || cookies.get('sc_walk_on_mobile')===undefined) {
            //no cookie exists, so show the walk me tour
            this.setState({
              run: true,
            });
          }
        }
      } else {
        if (process.env.GATSBY_ACTIVE_ENV === 'development') {
          console.log('***A/B TESTING*** width is zero, running window.innerWidth check')
        }
        if (typeof window !== 'undefined') {
          if (process.env.GATSBY_ACTIVE_ENV === 'development') {
            console.log('***A/B TESTING*** window.innerWidth - ' + window.innerWidth)
          }
          if (window.innerWidth < 700){
            //before setting the state to show the walk me tutorial, check to see if a cookie exists (it has been dismissed)
            if(cookies.get('sc_walk_on_mobile')===null || cookies.get('sc_walk_on_mobile')===undefined) {
              //no cookie exists, so show the walk me tour
              this.setState({
                run: true,
              });
            }
          }
        }
      }
    }

    if(this.state.modal === false){
        //Enable scroll when survey completes
      if ((destination.index === 7) || (destination.index === 8 )){
        this.enableScroll()
      }

      //Disable scroll if jumping back into the survey from the bettery way lead page
      if ((origin.index === 7 ) && (destination.index !== 8)) {
        this.disableScroll()
      } 
    } else {
      this.disableScroll()
    }
  }


  moveSectionDown() {
    window.fullpage_api.moveSectionDown();
  }


  componentDidMount () {
    this.update();
  }


  componentDidUpdate () {
    //enables scrolling if viewing on a mobile device.
    if (this.state.modal === false) {
      if (this.state.width < 700)
      {
        this.enableScroll()
      } else {
        this.disableScroll()
      }
    } else {
      this.disableScroll()
    }
  }


  componentWillUnmount() {
    // code removed with modal shifted to main landing page
  }


  enableScroll = () => {
      typeof window !== 'undefined' && window.fullpage_api.setAllowScrolling(true);
      typeof window !== 'undefined' && window.fullpage_api.setKeyboardScrolling(true);
  }

  disableScroll = () => {
      typeof window !== 'undefined' && window.fullpage_api.setAllowScrolling(false);
      typeof window !== 'undefined' && window.fullpage_api.setKeyboardScrolling(false);
  }


  update = () => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth
    });
    // console.log("**Update WINDOW WIDTH**" + this.state.width)
  };


  handleClickStart = (e) => {
    e.preventDefault();
    this.setState({
      run: true,
    });
  };


  handleJoyrideCallback = (data) => {
    const { joyride } = this.props;
    const { type } = data;

    if (type === EVENTS.TOUR_END && this.state.run) {
      this.setState({ run: false });
      //check to see if on a mobile device, if so, set cookie
      if (this.state.width < 700) {
        cookies.set('sc_walk_on_mobile', "Dismissed", { //cookie set to expire after 30 minutes
          path: '/',
          maxAge: 1800,
        })
      }
    } 

    //this will hide the beacon from appearing after close, but does not reset the index
    if (data.action === 'close') {
      this.setState({ run: false });
    }

    //user has selected to skip the tutorial
    if (data.action === 'skip') {
      //check to see if on a mobile device, if so, set cookie
      if (this.state.width < 700) {
        cookies.set('sc_walk_on_mobile', "Dismissed", { //cookie set to expire after 30 minutes
          path: '/',
          maxAge: 1800,
        })
      }
    }

    if (typeof joyride.callback === 'function') {
      joyride.callback(data);
    } else {
      // console.group(type);
      // console.log(data); //eslint-disable-line no-console
      // console.groupEnd();
    }
  };

  
  setModalIsOpenToTrue = (e) => {
    e.preventDefault();
        //checks to see if user has either dismissed or completed the survey - do not show the modal
      const modalCheck = typeof window !== 'undefined' && window.localStorage.getItem('stickchart:_sc_registered');

      //disable scrolling again
      if (process.env.GATSBY_ACTIVE_ENV === 'development') {
        console.log('***calling disableScroll before modal checks**')
      }
      this.disableScroll()
      
      if((modalCheck === 'true') || (modalCheck === 'dismissed')){
          //here the user has either completed or dismissed the survey
          //set registration modal state to false - do not show & navigate to the better way page
          this.setState({
            modal: false,
          });
          
          navigate(`/bettercustomer`)
      }
      else {
          //set registration modal state to true - show modal
          this.setState({
            modal: true,
          });
          
      }
  }

  render() {

    const { fullpages } = this.state;
    
    const { run, steps } = this.state;

    var varSection1, varSection2, varSection3, varSection4, varSection5, varSection6, varSection7;

    {this.props.data.allStrapiCustomerScrolls.nodes.map(data => {
        switch (data.nodetype) {
          case 'comms':
            return (
              varSection2 = data
            )
          case 'orders':
            return(
              varSection3 = data
            )
          case 'issues':
            return(
              varSection4 = data
            )
          case 'visits':
            return(
              varSection5 = data
            )
          case 'marketing':
            return(
              varSection6 = data
            )
          case 'misc':
            return(
              varSection7 = data
            )
          default:
            return(
              //Any code needed here? Dummy placeholder below
              varSection1 = data
            )
        }
      })}

    if (!fullpages.length) {
      return null;
    }


    return (
        <div className="Customer">
            <Layout>
                <RegistrationModal 
                  displayModal={this.state.modal}
                  closeModal={this.selectModal}
                  usertype="customer"
                />
                <SEO title={this.props.data.allStrapiCustomerHero.nodes[0].SEOtitle} description={this.props.data.allStrapiCustomerHero.nodes[0].SEOdescription}/>
                <ReactFullpage
                    //debug /* Debug logging */
                    // Required when using extensions
                    pluginWrapper={pluginWrapper}
                    // fullpage options
                    licenseKey={'9E0872D0-AEEF47E5-9CF4DA94-79D1D1E6'} // Added on 3/5/2021
                    navigation
                    navigationPosition='left'
                    navigationTooltips={tooltips}
                    slidesNavigation={false}
                    controlArrows={false}
                    showActiveTooltip={true}
                    // remarked out - but left in for further testing
                    // scrollOverflow={true}
                    // scrollOverflowOptions={
                    //   {click: false}
                    // }
                    anchors={['firstPage', 'secondPage', 'thirdPage','fourthPage', 'fifthPage', 'sixthPage','seventhPage', 'eightPage', 'ninthPage']}
                    sectionSelector={SECTION_SEL}
                    onLeave={this.onLeave.bind(this)}
                    afterLoad={this.afterLoad.bind(this)}
                    sectionsColor={this.state.sectionsColor}
                    touchSensitivity={5} 
                    scrollingSpeed={1000}
                    animateAnchor={false}      
                    // scrollOverflow={true}
                    normalScrollElements='.form-group'

                    render={({ state, fullpageApi }) => (
                        <ReactFullpage.Wrapper>
                        {fullpages.map(({ option }) => (
                            <div id="fullpage" key={option} className={`${SEL} ${option === 'Section9' && 'fp-auto-height'}`}>
                                {(() => {
                                    switch ( option ) {
                                        case 'Section1':
                                            return (
                                              <section class="section flex h-full bg-cover bg-gradient-to-tl from-sc-primary-blue to-white bg-no-repeat">
                                                <div class="section flex w-full h-full bg-wave-bottom bg-bottom bg-no-repeat">
                                                  <div class="w-full m-auto">
                                                    {/* test code left in below - help to quickly see height and width of screen */}
                                                    {/* <React.Fragment>
                                                      <p>height: {this.state.height}</p>
                                                      <p>width: {this.state.width}</p>
                                                    </React.Fragment> */}

                                                    {/* load hero component */}
                                                    <CustomerHero hero={this.props.data.allStrapiCustomerHero.nodes} />
                                                  </div>
                                                </div>
                                              </section>
                                            )
                                        case 'Section2':
                                            return (
                                              // First two divs help stabilize and align the page from a fullpage.js perspective
                                              <div class="section flex h-full scmd:h-screen bg-cover bg-sticks-blue bg-top sclg:bg-left-bottom bg-no-repeat">
                                                <div class="container flex items-center mx-auto w-full scxl:w-10/12 justify-end">
                                                  <button onClick={this.handleClickStart} class="hidden scmd:block absolute top-20 sclg:top-24 w-full scmd:w-auto btn-outline-primary rounded shadow-md transition duration-500 ease-in-out hover:shadow-2xl transform hover:-translate-y-1 hover:scale-110">
                                                      <span class="text-xs scmd:text-sm">How About a Quick Tour?</span>
                                                  </button>
                                                  {/* ReactJoyride to display the walk me tutorial on the initial survey screen */}
                                                  <ReactJoyride
                                                    continuous
                                                    disableOverlayClose
                                                    disableScrollParentFix
                                                    disableScrolling
                                                    showProgress
                                                    showSkipButton
                                                    run={run}
                                                    steps={steps}
                                                    styles={{
                                                      options: {
                                                        zIndex: 10000,
                                                      },
                                                      buttonClose: {
                                                        display: 'none',
                                                      },
                                                      buttonNext: {
                                                        backgroundColor: '#005993',
                                                        borderColor: '#005993',
                                                      },
                                                      buttonBack: {
                                                        color: '#a38e74',
                                                      }
                                                    }}
                                                    callback={this.handleJoyrideCallback}
                                                  />
                                                  {/* Defines the master grid for the layout - 2 Column on tablet and above, 1 Column for Smartphone */}
                                                  <div class="grid grid-cols-1 grid-rows-auto-1fr scmd:grid-cols-2 scmd:grid-rows-1 scmd:gap-4 h-full scmd:h-auto">
                                                    { /* <!--First (Left/Top) Col--> */}
                                                    <div id="todo-list-1" class="flex flex-col justify-center text-black text-left bg-gray-100 px-8 pt-12 scmd:p-16 scmd:bg-transparent">
                                                      {/* <h1>First Column</h1> */}
                                                      <Kicker kicker={varSection2.kicker} nodetype={varSection2.nodetype} usertype="customer"/>
                                                      <ScrollContent heading={varSection2.heading} nodetype={varSection2.nodetype} todolist={varSection2.todolist} usertype="customer"/>
                                                    </div>
                                                    <div class="flex flex-wrap text-black text-left feedback scmd:border scmd:rounded-md bg-white shadow-2xl">
                                                      {/* <h1>Second Column</h1> */}
                                                      <FeedbackSwiper nodetype={varSection2.nodetype} satangry={varSection2.satangry} satfrown={varSection2.satfrown} satmeh={varSection2.satmeh} satsmile={varSection2.satsmile} satquestion={varSection2.satquestion} freequestion={varSection2.freequestion} techquestion={varSection2.techquestion} usertype="customer"/>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                        case 'Section3':
                                            return (
                                              // First two divs help stabilize and align the page from a fullpage.js perspective
                                              <div class="section flex h-full scmd:h-screen bg-cover bg-sticks-green bg-top sclg:bg-left-bottom bg-no-repeat">
                                                <div class="container flex items-center mx-auto w-full">
                                                  {/* Defines the master grid for the layout - 2 Column on tablet and above, 1 Column for Smartphone */}
                                                  <div class="grid grid-cols-1 grid-rows-auto-1fr scmd:grid-cols-2 scmd:grid-rows-1 scmd:gap-4 h-full scmd:h-auto scmd:px-6 sclg:px-2 scxl:px-36">
                                                    { /* <!--First (Left/Top) Col--> */}
                                                    <div class="flex flex-col justify-center text-black text-left bg-gray-100 px-8 pt-16 pb-8 scmd:p-16 scmd:bg-transparent">
                                                      {/* <h1>First Column</h1> */}
                                                      <Kicker kicker={varSection3.kicker} nodetype={varSection3.nodetype} usertype="customer"/>
                                                      <ScrollContent heading={varSection3.heading} nodetype={varSection3.nodetype} todolist={varSection3.todolist} usertype="customer"/>
                                                    </div>
                                                    <div class="flex flex-wrap text-black text-left feedback scmd:border scmd:rounded-md bg-white shadow-2xl">
                                                      {/* <h1>Second Column</h1> */}
                                                      <FeedbackSwiper nodetype={varSection3.nodetype} satangry={varSection3.satangry} satfrown={varSection3.satfrown} satmeh={varSection3.satmeh} satsmile={varSection3.satsmile} satquestion={varSection3.satquestion} freequestion={varSection3.freequestion} techquestion={varSection3.techquestion} usertype="customer"/>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                        case 'Section4':
                                            return (
                                              // First two divs help stabilize and align the page from a fullpage.js perspective
                                              <div class="section flex h-full scmd:h-screen bg-cover bg-sticks-pink bg-top sclg:bg-left-bottom bg-no-repeat">
                                                <div class="container flex items-center mx-auto w-full">
                                                  {/* Defines the master grid for the layout - 2 Column on tablet and above, 1 Column for Smartphone */}
                                                  <div class="grid grid-cols-1 grid-rows-auto-1fr scmd:grid-cols-2 scmd:grid-rows-1 scmd:gap-4 h-full scmd:h-auto scmd:px-6 sclg:px-2 scxl:px-36">
                                                    { /* <!--First (Left/Top) Col--> */}
                                                    <div class="flex flex-col justify-center text-black text-left bg-gray-100 px-8 pt-16 scmd:p-16 scmd:bg-transparent">
                                                      {/* <h1>First Column</h1> */}
                                                      <Kicker kicker={varSection4.kicker} nodetype={varSection4.nodetype} usertype="customer"/>
                                                      <ScrollContent heading={varSection4.heading} nodetype={varSection4.nodetype} todolist={varSection4.todolist} usertype="customer"/>
                                                    </div>
                                                    <div class="flex flex-wrap text-black text-left feedback scmd:border scmd:rounded-md bg-white shadow-2xl">
                                                      {/* <h1>Second Column</h1> */}
                                                      <FeedbackSwiper nodetype={varSection4.nodetype} satangry={varSection4.satangry} satfrown={varSection4.satfrown} satmeh={varSection4.satmeh} satsmile={varSection4.satsmile} satquestion={varSection4.satquestion} freequestion={varSection4.freequestion} techquestion={varSection4.techquestion} usertype="customer"/>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                        case 'Section5':
                                            return (
                                              // First two divs help stabilize and align the page from a fullpage.js perspective
                                              <div class="section flex h-full scmd:h-screen bg-cover bg-sticks-yellow bg-top sclg:bg-left-bottom bg-no-repeat">
                                                <div class="container flex items-center mx-auto w-full">
                                                  {/* Defines the master grid for the layout - 2 Column on tablet and above, 1 Column for Smartphone */}
                                                  <div class="grid grid-cols-1 grid-rows-auto-1fr scmd:grid-cols-2 scmd:grid-rows-1 scmd:gap-4 h-full scmd:h-auto scmd:px-6 sclg:px-2 scxl:px-36">
                                                    { /* <!--First (Left/Top) Col--> */}
                                                    <div class="flex flex-col justify-center text-black text-left bg-gray-100 px-8 pt-16 pb-2 scmd:p-16 scmd:bg-transparent">
                                                      {/* <h1>First Column</h1> */}
                                                      <Kicker kicker={varSection5.kicker} nodetype={varSection5.nodetype} usertype="customer"/>
                                                      <ScrollContent heading={varSection5.heading} nodetype={varSection5.nodetype} todolist={varSection5.todolist} usertype="customer"/>
                                                    </div>
                                                    <div class="flex flex-wrap text-black text-left feedback scmd:border scmd:rounded-md bg-white shadow-2xl">
                                                      {/* <h1>Second Column</h1> */}
                                                      <FeedbackSwiper nodetype={varSection5.nodetype} satangry={varSection5.satangry} satfrown={varSection5.satfrown} satmeh={varSection5.satmeh} satsmile={varSection5.satsmile} satquestion={varSection5.satquestion} freequestion={varSection5.freequestion} techquestion={varSection5.techquestion} usertype="customer"/>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                        case 'Section6':
                                            return (
                                              // First two divs help stabilize and align the page from a fullpage.js perspective
                                              <div class="section flex h-full scmd:h-screen bg-cover bg-sticks-blue bg-top sclg:bg-left-bottom bg-no-repeat">
                                                <div class="container flex items-center mx-auto w-full">
                                                  {/* Defines the master grid for the layout - 2 Column on tablet and above, 1 Column for Smartphone */}
                                                  <div class="grid grid-cols-1 grid-rows-auto-1fr scmd:grid-cols-2 scmd:grid-rows-1 scmd:gap-4 h-full scmd:h-auto scmd:px-6 sclg:px-2 scxl:px-36">
                                                    { /* <!--First (Left/Top) Col--> */}
                                                    <div class="flex flex-col justify-center text-black text-left bg-gray-100 px-8 pt-16 pb-4 scmd:p-16 scmd:bg-transparent">
                                                      {/* <h1>First Column</h1> */}
                                                      <Kicker kicker={varSection6.kicker} nodetype={varSection6.nodetype} usertype="customer"/>
                                                      <ScrollContent heading={varSection6.heading} nodetype={varSection6.nodetype} todolist={varSection6.todolist} usertype="customer"/>
                                                    </div>
                                                    <div class="flex flex-wrap text-black text-left feedback scmd:border scmd:rounded-md bg-white shadow-2xl">
                                                      {/* <h1>Second Column</h1> */}
                                                      <FeedbackSwiper nodetype={varSection6.nodetype} satangry={varSection6.satangry} satfrown={varSection6.satfrown} satmeh={varSection6.satmeh} satsmile={varSection6.satsmile} satquestion={varSection6.satquestion} freequestion={varSection6.freequestion} techquestion={varSection6.techquestion} usertype="customer"/>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                        case 'Section7':
                                            return (
                                              // First two divs help stabilize and align the page from a fullpage.js perspective
                                              <div class="section flex h-full scmd:h-screen bg-cover bg-sticks-green bg-top sclg:bg-left-bottom bg-no-repeat">
                                                <div class="container flex items-center mx-auto w-full">
                                                  {/* Defines the master grid for the layout - 2 Column on tablet and above, 1 Column for Smartphone */}
                                                  <div class="grid grid-cols-1 grid-rows-auto-1fr scmd:grid-cols-2 scmd:grid-rows-1 scmd:gap-4 h-full scmd:h-auto scmd:px-6 sclg:px-2 scxl:px-36">
                                                    { /* <!--First (Left/Top) Col--> */}
                                                    <div class="flex flex-col justify-center text-black text-left bg-gray-100 px-7 scmd:px-8 pt-12 scmd:p-16 scmd:bg-transparent">
                                                      {/* <h1>First Column</h1> */}
                                                      <Kicker kicker={varSection7.kicker} nodetype={varSection7.nodetype} usertype="customer"/>
                                                      <ScrollContent heading={varSection7.heading} nodetype={varSection7.nodetype} todolist={varSection7.todolist} usertype="customer"/>
                                                    </div>
                                                    <div class="flex flex-wrap text-black text-left feedback scmd:border scmd:rounded-md bg-white shadow-2xl">
                                                      {/* <h1>Second Column</h1> */}
                                                      <FeedbackSwiper nodetype={varSection7.nodetype} satangry={varSection7.satangry} satfrown={varSection7.satfrown} satmeh={varSection7.satmeh} satsmile={varSection7.satsmile} satquestion={varSection7.satquestion} freequestion={varSection7.freequestion} techquestion={varSection7.techquestion} usertype="customer"/>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                        case 'Section8':
                                            return (
                                                // First two divs help stabilize and align the page from a fullpage.js perspective
                                                <div class="section flex h-full sclg:h-screen bg-gradient-to-tl from-sc-primary-blue-500 to-white">
                                                  <div class="section flex w-full h-full sclg:h-screen bg-wave-bottom bg-bottom bg-no-repeat">
                                                  <div class="flex items-center mx-auto w-full ">
                                                      {/* Defines the master grid for the layout - 3 Column on tablet and above, 1 Column for Smartphone */}
                                                      <div class="max-w-screen-md mx-auto my-0 relative px-6 scmd:px-32">
                              
                                                        <div class="flex flex-wrap w-full content-center">
                                                          <div class="w-full m-auto flex-grow-0 flex-shrink-0 text-gray-600 text-left pl-4 scmd:pl-0">
                                                            {/* Content Div - Better Way Message */}
                                                            <h3 class="mb-8">{this.props.data.allStrapiBetterLandings.nodes[0].heading}</h3>
                                                            <p class="text-base sclg:text-xl text-gray-500 font-medium leading-tight">
                                                                <ReactMarkdown source={this.props.data.allStrapiBetterLandings.nodes[0].body} />
                                                            </p>
                                                            <React.Fragment >
                                                              <button id="btn-surveybetter-customer-id" onClick={this.setModalIsOpenToTrue} class="w-full scmd:w-auto btn-outline-primary rounded shadow-md transition duration-500 ease-in-out hover:shadow-2xl transform hover:-translate-y-1 hover:scale-110"> 
                                                                <span class="text-base scmd:text-lg">Click to See a Better Way</span>
                                                              </button>
                                                            </React.Fragment>
                                                          </div>
                                                        </div>

                                                      </div>
                                                  </div>
                                                  </div>
                                                </div>
                                            )
                                        case 'Section9':
                                            return (
                                              <div class="text-base text-left"> 
                                                <div >
                                                  <Footer />
                                                </div>
                                              </div>
                                            )
                                        default:
                                            return (
                                                <div class="h-screen">
                                                    <h1 class="text-black">No section match</h1>
                                                    <h1 class="text-black">Second Return value validated</h1>
                                                    <h1 class="text-black">This is what is the option - {option}</h1> 
                                                </div>
                                            )
                                    }
                                })()}
                            </div>
                        ))}
                        </ReactFullpage.Wrapper>
                    )}
                />
        </Layout>
      </div>
    );
  }
}


export default () => (

  <StaticQuery
    query={graphql`
      {
        allStrapiCustomerScrolls {
          nodes {
            kicker
            heading
            todolist
            id
            nodetype
            satquestion
            techquestion
            freequestion
            satangry
            satfrown
            satmeh
            satsmile
          }
        }
        allStrapiBetterLandings(filter: {usertype: {eq: "customer"}}) {
          nodes {
            heading
            body
          }
        }
        allStrapiCustomerHero {
          nodes {
            altText
            SEOdescription
            SEOtitle
            heading
            subheading
            tagline
            body
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => (
      <Customer data={data} />
    )}
  />

)

